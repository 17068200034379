import Joi from "joi";
import { validation } from "../utils/validation.util";
const validateName = (value, helper) => {
    if (!validation.isValidLettersOnly(value, [" ", "-"])) {
        return helper.message("{{#label}} must not contain symbols or numbers");
    }
    else {
        return true;
    }
};
const create = () => {
    const user = Joi.object({
        firstName: Joi.string().trim()
            .max(1)
            .max(49)
            .custom(validateName)
            .required()
            .label("First name"),
        lastName: Joi.string().trim()
            .max(1)
            .max(49)
            .custom(validateName)
            .required()
            .label("Last name"),
    });
    return Joi.object({
        invitedUsers: Joi.array().items(user)
    });
};
export default { create };
