const commonSymbols = [' ', '-', ',', '.', '(', ')', '`', '&'];
const isLetter = (value) => {
    const lowerCase = value.toLocaleLowerCase();
    return lowerCase >= 'a' && lowerCase <= 'z';
};
const isOneOf = (valid) => {
    return (value) => {
        return valid.indexOf(value) !== -1;
    };
};
const or = (funcs) => {
    return (value) => {
        return funcs.filter(func => func(value)).length > 0;
    };
};
const isStringLessThanMaxLength = (value, maxlength) => {
    return value.length <= maxlength;
};
const isNotProvided = (value) => {
    let isNull = value === null || typeof value === "undefined";
    let isEmpty = value === '';
    return isNull || isEmpty;
};
const isProvidedAsLetters = (value, allowedSymbols) => {
    let valueToBeValidated = '';
    let provided = value && typeof value === 'string' && value !== '';
    if (provided) {
        valueToBeValidated = String(value);
    }
    const filter = or([isLetter, isOneOf(allowedSymbols)]);
    let validCharacters = valueToBeValidated
        .split("")
        .filter(x => !filter(x))
        .length === 0;
    return provided && validCharacters;
};
const isProvidedAsAlphanumeric = (value, allowedSymbols) => {
    let valueToBeValidated = '';
    let provided = value && typeof value === 'string' && value !== '';
    if (provided) {
        valueToBeValidated = String(value);
    }
    const allowedCharacters = [
        ...allowedSymbols, '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
    ];
    const filter = or([isLetter, isOneOf(allowedCharacters)]);
    let validCharacters = valueToBeValidated
        .split("")
        .filter(x => !filter(x))
        .length === 0;
    return provided && validCharacters;
};
export const validation = {
    getValidationError: (fieldName, validationFields, validationResult) => {
        var _a;
        if (!validationFields || Object.keys(validationFields).indexOf(fieldName) < 0) {
            return "";
        }
        const error = (_a = validationResult.error) === null || _a === void 0 ? void 0 : _a.details.filter(x => { var _a; return ((_a = x.context) === null || _a === void 0 ? void 0 : _a.key) === fieldName; })[0];
        if (error) {
            return error.message;
        }
        return "";
    },
    commonSymbols: () => {
        return commonSymbols;
    },
    isValidAlphanumeric: (value, allowedSymbols) => {
        return isProvidedAsAlphanumeric(value, allowedSymbols);
    },
    isValidLettersOnly: (value, allowedSymbols) => {
        return isProvidedAsLetters(value, allowedSymbols);
    },
    isAddressValid: (address) => {
        if (!address) {
            return false;
        }
        return (isProvidedAsAlphanumeric(address.address1, commonSymbols) && isStringLessThanMaxLength(address.address1, 49) &&
            (isNotProvided(address.address2) || (isProvidedAsAlphanumeric(address.address2, commonSymbols) && isStringLessThanMaxLength(address.address2, 49))) &&
            (isNotProvided(address.address3) || (isProvidedAsAlphanumeric(address.address3, commonSymbols) && isStringLessThanMaxLength(address.address3, 49))) &&
            isProvidedAsLetters(address.town, commonSymbols) && isStringLessThanMaxLength(address.town, 85) &&
            (isNotProvided(address.county) || (isProvidedAsLetters(address.county, commonSymbols) && isStringLessThanMaxLength(address.county, 56))) &&
            isProvidedAsLetters(address.country, commonSymbols) && isStringLessThanMaxLength(address.country, 56) &&
            isProvidedAsAlphanumeric(address.postcode, [" "]) && isStringLessThanMaxLength(address.postcode, 10));
    },
    isTelephoneNumberValid: (telephoneNumber) => {
        const validChars = [
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '(', ')', '+', '-'
        ];
        if (!telephoneNumber) {
            return false;
        }
        const numberDigits = [...telephoneNumber]
            .filter((x) => x !== " ")
            .filter((x) => !isNaN(x))
            .length;
        if (numberDigits < 9 || numberDigits > 15) {
            return false;
        }
        return telephoneNumber.split("").filter(x => validChars.indexOf(x) === -1).length <= 0;
    },
};
export const validateName = (value, helper) => {
    if (!validation.isValidLettersOnly(value, [" ", "-"])) {
        return helper.message("{{#label}} must not contain symbols or numbers");
    }
    else {
        return true;
    }
};
export const validatePhone = (value, helper) => {
    if (!validation.isTelephoneNumberValid(value)) {
        return helper.message("{{#label}} must be a valid telephone number");
    }
    else {
        return true;
    }
};
export const validateAddress = (address, helper) => {
    if (!validation.isAddressValid(address)) {
        return helper.message("{{#label}} must be a valid address");
    }
    else {
        return true;
    }
};
