import processBuilder from "../processes/processBuilder";
import dataStore from "../localStorage/dataStore";
const generateStore = () => ({
    components: {
        error: {
            isError: false,
            message: null
        }
    },
    process: {
        currentProcessId: "NEW_USER",
        processes: processBuilder.build()
    },
    data: dataStore.get() || {}
});
export default generateStore;
