import Joi from "joi";
import { validateName } from "../utils/validation.util";
const agreeTerms = (value, helper) => {
    if (!value) {
        return helper.message("Must agree to terms and conditions");
    }
    else {
        return true;
    }
};
const create = () => {
    return Joi.object({
        email: Joi.string()
            .max(100)
            .email({ tlds: { allow: false } })
            .required(),
        firstName: Joi.string().trim()
            .max(1)
            .max(49)
            .custom(validateName)
            .required()
            .label("First name"),
        lastName: Joi.string().trim()
            .max(1)
            .max(49)
            .custom(validateName)
            .required()
            .label("Last name"),
        contactAboutFeatures: Joi.boolean()
            .label("Contact about features"),
        terms: Joi.boolean()
            .custom(agreeTerms)
            .required()
            .label("Terms"),
    });
};
export default { create };
