import generateStore from "./storeGenerator";
const componentReducer = (state = generateStore().components, action = { type: "" }) => {
    switch (action.type) {
        case "SET_ERROR":
            return Object.assign(Object.assign({}, state), { error: {
                    isError: true,
                    message: action.message
                } });
        case "CLEAR_ERROR":
            return Object.assign(Object.assign({}, state), { error: {
                    isError: false,
                    message: null
                } });
        default:
            return state;
    }
};
export default componentReducer;
