import React, { useEffect, useRef } from "react";
import { hot } from "react-hot-loader";
const Selector = (props) => {
    const { size, label, required, value, data, onChanged } = props;
    const selectorRef = useRef(null);
    const onSelectorChanged = (x) => onChanged(x);
    /* istanbul ignore next */
    useEffect(() => {
        if (selectorRef.current) {
            selectorRef.current.addEventListener('changed', onSelectorChanged);
            return function cleanup() {
                selectorRef.current.removeEventListener('changed', onSelectorChanged); // Clean up the listener on component unmount
            };
        }
    });
    return (React.createElement("iris-select", { id: props.id, ref: selectorRef, size: size, label: label, required: required, value: value, "object-data": JSON.stringify(data) }));
};
export default hot(module)(Selector);
