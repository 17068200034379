import { useHistory } from "react-router-dom";
const getProcessId = (processes, path) => {
    const matchingProcess = processes.filter(process => process.steps.filter(step => step.url == path).length === 1);
    if (matchingProcess.length === 1) {
        return matchingProcess[0].id;
    }
    return null;
};
const ProcessSelector = (props) => {
    var _a;
    const history = useHistory();
    const currentPath = (_a = history === null || history === void 0 ? void 0 : history.location) === null || _a === void 0 ? void 0 : _a.pathname;
    const processId = getProcessId(props.processState.processes, currentPath);
    if (processId !== props.processState.currentProcessId) {
        props.setProcess(processId);
    }
    return null;
};
export { ProcessSelector };
