import React from 'react';
import ConfirmationStep from "./ConfirmationStep";
import ProcessScreenSelect from "../process/ProcessScreenSelect";
import { useHistory } from "react-router-dom";
import AutomaticStep from "./AutomaticStep";
import ManualStep from "./ManualStep";
import NewUserDetailsForm from './newUserDetailsForm';
const Process = (props) => {
    var _a;
    const history = useHistory();
    const currentPath = (_a = history === null || history === void 0 ? void 0 : history.location) === null || _a === void 0 ? void 0 : _a.pathname;
    if (currentPath && currentPath !== props.currentStep.url) {
        window.history.replaceState(null, '', props.currentStep.url);
    }
    switch (props.currentStep.type) {
        case "new-user-manual":
            return React.createElement(NewUserDetailsForm, { step: props.currentStep, onNextStep: props.onNextStep },
                React.createElement(ProcessScreenSelect, { step: props.currentStep, data: props.data, setValue: props.setValue }));
        case "manual":
            return React.createElement(ManualStep, { step: props.currentStep, steps: props.currentProcess.steps, currentIndex: props.currentIndex, onNextStep: props.onNextStep, onPreviousStep: props.onPreviousStep },
                React.createElement(ProcessScreenSelect, { step: props.currentStep, data: props.data, setValue: props.setValue }));
        case "automatic":
            const automaticStep = props.currentStep;
            return React.createElement(AutomaticStep, { step: props.currentStep, data: props.data, submit: () => props.submit(automaticStep.process(props.state, props.dispatch)), onPreviousStep: props.onPreviousStep },
                React.createElement(ProcessScreenSelect, { step: props.currentStep, data: props.data, setValue: props.setValue }));
        case "confirmation":
            const confirmationStep = props.currentStep;
            return React.createElement(ConfirmationStep, { step: props.currentStep, data: props.data, submit: () => props.submit(confirmationStep.process(props.state, props.dispatch)) },
                React.createElement(ProcessScreenSelect, { step: props.currentStep, data: props.data, setValue: props.setValue }));
    }
};
export { Process };
