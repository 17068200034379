import Joi from "joi";
import { validation } from "../utils/validation.util";
const validateAlphanumeric = (value, helper) => {
    if (!validation.isValidAlphanumeric(value, validation.commonSymbols())) {
        return helper.message("{{#label}} is not valid");
    }
    else {
        return true;
    }
};
const validateString = (value, helper) => {
    if (!validation.isValidLettersOnly(value, validation.commonSymbols())) {
        return helper.message("{{#label}} is not valid");
    }
    else {
        return true;
    }
};
const validatePostCode = (value, helper) => {
    if (!validation.isValidAlphanumeric(value, [" "])) {
        return helper.message("{{#label}} is not valid");
    }
    else {
        return true;
    }
};
const create = () => {
    return Joi.object({
        address1: Joi.string()
            .required()
            .max(49)
            .custom(validateAlphanumeric)
            .label("Address Line 1"),
        address2: Joi.string().trim()
            .optional()
            .allow(null)
            .allow('')
            .max(49)
            .custom(validateAlphanumeric)
            .label("Address Line 2"),
        address3: Joi.string().trim()
            .optional()
            .allow(null)
            .allow('')
            .max(49)
            .custom(validateAlphanumeric)
            .label("Address Line 3"),
        town: Joi.string()
            .required()
            .max(85)
            .custom(validateString)
            .label("Town"),
        county: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .max(56)
            .custom(validateString)
            .label("County"),
        country: Joi.string()
            .required()
            .max(56)
            .custom(validateString)
            .label("Country"),
        postcode: Joi.string()
            .required()
            .max(10)
            .custom(validatePostCode)
            .label("Post Code"),
    });
};
export default { create };
