import { connect } from "react-redux";
import { gotoStep } from '../../store/actionCreators';
import StepsTracker from "./StepsTracker";
const getCurrentProcess = (state) => state.process.processes.filter(x => x.id === state.process.currentProcessId)[0];
const mapStateToProps = (state, ownProps) => {
    const process = getCurrentProcess(state);
    return {
        steps: process.steps,
        currentIndex: process.currentStepIndex,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onGotoStep: (id) => dispatch(gotoStep(id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(StepsTracker);
