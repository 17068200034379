import axios from 'axios';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { cleanup } from '../utils/cleanup';
import { countriesArray } from '../utils/countryList';
import { validation } from '../utils/validation.util';
import getEnvironment from '../getEnvironment';
import addressFieldValidation from "../validation/addressFieldValidation";
import ValidationError from "./common/ValidationError";
import Selector from "./common/Selector";
const schema = addressFieldValidation.create();
const AddressForm = (props) => {
    const { setValue } = props;
    const { address1, address2, address3, town, county, country, postcode } = props.data;
    const [postcodeSearch, setPostcodeSearch] = useState('');
    const [manualAddress, setManual] = useState(false);
    const [countries, setCountries] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [foundAddresses, setFoundAddresses] = useState(false);
    const updateState = (value, field) => {
        const businessAddress = {
            address1,
            address2,
            address3,
            town,
            county,
            country,
            postcode
        };
        switch (field) {
            case "address1":
                businessAddress.address1 = value;
                break;
            case "address2":
                businessAddress.address2 = value;
                break;
            case "address3":
                businessAddress.address3 = value;
                break;
            case "town":
                businessAddress.town = value;
                break;
            case "county":
                businessAddress.county = value;
                break;
            case "postcode":
                businessAddress.postcode = value;
                break;
            case "country":
                businessAddress.country = value;
                break;
        }
        setValue("businessAddress", businessAddress);
    };
    const validationResult = schema.validate(props.data, { abortEarly: false });
    const addressSelect = (addressStr) => {
        const address = JSON.parse(addressStr);
        setValue("businessAddress", address);
        updateAddress(address);
    };
    const getShortAddress = (address) => {
        return `${address.address1}, ${address.town}, ${address.county}`;
    };
    const getAddressesFromPostcode = () => {
        const environment = getEnvironment.get();
        setFoundAddresses(false);
        return new Promise(resolve => {
            axios({
                method: 'GET',
                url: `${environment.lookup_address_api_uri}/address/postcode/${postcodeSearch}`,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${environment.lookup_address_api_key}`
                }
            })
                .then((response) => {
                if (response.data.code === 4040) {
                    setAddressList([]);
                    setFoundAddresses(true);
                    resolve(response.data.message);
                }
                else {
                    const addresses = response.data.result.map(a => ({
                        address1: cleanup.changeOfChar(a.line_1),
                        address2: cleanup.changeOfChar(a.line_2),
                        address3: cleanup.changeOfChar(a.line_3),
                        town: cleanup.changeOfChar(a.post_town),
                        county: cleanup.changeOfChar(a.county),
                        country: cleanup.changeOfChar(a.country),
                        postcode: cleanup.changeOfChar(a.postcode)
                    }));
                    const addressDropdownList = addresses.map(a => ({
                        name: getShortAddress(a),
                        value: JSON.stringify(a)
                    }));
                    if (manualAddress) {
                        toggleEdit();
                    }
                    setAddressList(addressDropdownList);
                    setFoundAddresses(true);
                    resolve(response.data.result);
                }
            })
                .catch((err) => {
                setAddressList([]);
                setFoundAddresses(true);
                console.log(err);
                resolve(JSON.stringify(err));
            });
        });
    };
    const getCountries = () => {
        const environment = getEnvironment.get();
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${environment.lookup_countries_api_uri}`,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${environment.lookup_countries_api_key}`
                }
            })
                .then((response) => {
                resolve(response.data);
            })
                .catch((err) => {
                reject(err.toJSON());
                //resolve(clientRes) // dev only
            });
        });
    };
    const toggleEdit = () => {
        setManual(!manualAddress);
        if (!manualAddress && countries.length == 0) {
            getCountries().then(res => {
                if (res && res instanceof Array) { // This check is necessary because tslint won't believe res is an array otherwise.
                    setCountries(res.map(c => ({ name: c.name, value: c.name })));
                }
            }).catch(() => {
                setCountries(countriesArray.map(c => ({ name: c, value: c })));
            });
        }
    };
    const isValid = () => {
        return validation.isAddressValid(props.data);
    };
    const updateAddress = (address) => {
        props.onSubmit({
            address1: (address === null || address === void 0 ? void 0 : address.address1) || address1,
            address2: (address === null || address === void 0 ? void 0 : address.address2) || address2,
            address3: (address === null || address === void 0 ? void 0 : address.address3) || address3,
            town: (address === null || address === void 0 ? void 0 : address.town) || town,
            county: (address === null || address === void 0 ? void 0 : address.county) || county,
            postcode: (address === null || address === void 0 ? void 0 : address.postcode) || postcode,
            country: (address === null || address === void 0 ? void 0 : address.country) || country
        });
    };
    return (React.createElement("div", { className: "addressForm" },
        React.createElement("div", { className: "postcode-text" },
            React.createElement("label", { htmlFor: "txtEnterPostcode" },
                "Postcode",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { id: "txtEnterPostcode", name: "Postcode", type: "text", placeholder: "Enter postcode", value: postcodeSearch ? postcodeSearch : '', onChange: e => setPostcodeSearch(e.target.value) }),
            React.createElement("iris-button", { id: "btnFindAddress", colour: "secondary", size: "default", onClick: getAddressesFromPostcode }, "Find address")),
        addressList.length == 0 && foundAddresses &&
            React.createElement("div", { className: "no-result" },
                React.createElement("iris-icon", { type: "alert-triangle", size: "24", padding: "0", "icon-colour": "#FF9504", "background-colour": "#FFF4E6" }),
                React.createElement("span", null, "No results found for search.")),
        !manualAddress &&
            React.createElement("div", { className: "manual-address" },
                React.createElement("a", { className: "manual-address__link", onClick: toggleEdit }, "Enter address manually")),
        (addressList.length > 0 && foundAddresses) && !manualAddress &&
            React.createElement("div", { className: "address-list m-t-15" },
                React.createElement(Selector, { size: "45", label: "Select your address", required: true, data: addressList, value: "Select your address", onChanged: e => addressSelect(e.detail) })),
        address1 && !manualAddress &&
            React.createElement("div", { className: "address-block row-fx m-t-20" },
                React.createElement("div", { className: "col-10" },
                    React.createElement("ul", { id: "addressViewer" },
                        React.createElement("li", { id: "lblLineOne" }, address1),
                        address2 && React.createElement("li", { id: "lblLineTwo" }, address2),
                        address3 && React.createElement("li", { id: "lblLineThree" }, address3),
                        React.createElement("li", { id: "lblLineTown" }, town),
                        React.createElement("li", { id: "lblLineCounty" }, county),
                        React.createElement("li", { id: "lblPostcode" }, postcode),
                        React.createElement("li", { id: "lblLineCountry" }, country))),
                React.createElement("div", { className: "col-2 edit-block" },
                    React.createElement("a", { id: "anchorEdit", onClick: toggleEdit }, "Edit"))),
        manualAddress &&
            React.createElement("div", { className: "manual-address-form" },
                React.createElement("div", null,
                    React.createElement("section", null,
                        React.createElement("div", { className: "addr-form-group" },
                            React.createElement("label", { htmlFor: "txtLineOne" },
                                "Address line 1",
                                React.createElement("span", { className: "asterisk" }, "*")),
                            React.createElement("input", { id: "txtLineOne", type: "text", value: address1 ? address1 : '', onChange: e => updateState(cleanup.changeOfChar(e.target.value), "address1"), name: "addrline1", className: validation.getValidationError("address1", props.data, validationResult).length > 0 ? 'validation-error' : '' })),
                        validation.getValidationError("address1", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("address1", props.data, validationResult) })),
                    React.createElement("section", null,
                        React.createElement("div", { className: "addr-form-group" },
                            React.createElement("label", { htmlFor: "txtLineTwo" }, "Address line 2"),
                            React.createElement("input", { id: "txtLineTwo", type: "text", value: address2 ? address2 : '', onChange: e => updateState(cleanup.changeOfChar(e.target.value), "address2"), name: "addrline2", className: validation.getValidationError("address2", props.data, validationResult).length > 0 ? 'validation-error' : '' })),
                        validation.getValidationError("address2", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("address2", props.data, validationResult) })),
                    React.createElement("section", null,
                        React.createElement("div", { className: "addr-form-group" },
                            React.createElement("label", { htmlFor: "txtLineThree" }, "Address line 3"),
                            React.createElement("input", { id: "txtLineThree", type: "text", value: address3 ? address3 : '', onChange: e => updateState(cleanup.changeOfChar(e.target.value), "address3"), name: "addrline3", className: validation.getValidationError("address3", props.data, validationResult).length > 0 ? 'validation-error' : '' })),
                        validation.getValidationError("address3", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("address3", props.data, validationResult) })),
                    React.createElement("section", null,
                        React.createElement("div", { className: "addr-form-group" },
                            React.createElement("label", { htmlFor: "txtTown" },
                                "City/Town",
                                React.createElement("span", { className: "asterisk" }, "*")),
                            React.createElement("input", { id: "txtTown", type: "text", value: town ? town : '', onChange: e => updateState(cleanup.changeOfChar(e.target.value), "town"), required: true, name: "city-town", className: validation.getValidationError("town", props.data, validationResult).length > 0 ? 'validation-error' : '' })),
                        validation.getValidationError("town", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("town", props.data, validationResult) })),
                    React.createElement("section", null,
                        React.createElement("div", { className: "addr-form-group" },
                            React.createElement("label", { htmlFor: "txtCounty" }, "County"),
                            React.createElement("input", { id: "txtCounty", type: "text", value: county ? county : '', onChange: e => updateState(cleanup.changeOfChar(e.target.value), "county"), name: "county", className: validation.getValidationError("county", props.data, validationResult).length > 0 ? 'validation-error' : '' })),
                        validation.getValidationError("county", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("county", props.data, validationResult) })),
                    React.createElement("section", null,
                        React.createElement("div", { className: "addr-form-group" },
                            React.createElement("label", { htmlFor: "txtPostcode" },
                                "Postcode",
                                React.createElement("span", { className: "asterisk" }, "*")),
                            React.createElement("input", { id: "txtPostcode", type: "text", value: postcode ? postcode : '', onChange: e => updateState(cleanup.changeOfChar(e.target.value), "postcode"), name: "postcode", required: true, className: validation.getValidationError("postcode", props.data, validationResult).length > 0 ? 'validation-error' : '' })),
                        validation.getValidationError("postcode", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("postcode", props.data, validationResult) })),
                    React.createElement("div", { className: "addr-form-group" },
                        React.createElement(Selector, { id: 'countrySelector', size: "34", label: "Country", required: true, data: countries, value: country ? country : "Please select...", onChanged: e => updateState(e.detail, "country") }),
                        validation.getValidationError("country", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("country", props, validationResult) }))),
                React.createElement("div", { className: "btn-save" },
                    React.createElement("iris-button", { id: "btnSaveAddress", type: "button", size: "small", colour: "primary", disabled: !isValid(), onClick: () => { toggleEdit(); updateAddress(null); } }, "Update address")))));
};
export default hot(module)(AddressForm);
