var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import getEnvironment from "../getEnvironment";
let environment = null;
let userPoolId = null;
let clientId = null;
let poolData = null;
let currentUser = null;
let userPool = null;
const getCognitoUser = (username, userData) => {
    return new CognitoUser(userData);
};
const getAttributes = () => __awaiter(void 0, void 0, void 0, function* () {
    environment = getEnvironment.get();
    userPoolId = environment.elements1_userpoolid;
    clientId = environment.elements1_clientid;
    poolData = {
        UserPoolId: `${userPoolId}`,
        ClientId: `${clientId}`
    };
    return new Promise(function (resolve, reject) {
        currentUser.getUserAttributes(function (err, attributes) {
            if (err) {
                reject(err);
            }
            else {
                resolve(attributes);
            }
        });
    }).catch(err => {
        throw err;
    });
});
const getSession = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!currentUser) {
        currentUser = userPool.getCurrentUser();
    }
    return new Promise(function (resolve, reject) {
        currentUser.getSession(function (err, session) {
            if (err) {
                reject(err);
            }
            else {
                resolve(session);
            }
        });
    }).catch(err => {
        throw err;
    });
});
const signInWithEmail = (username, password) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise(function (resolve, reject) {
        const authenticationData = {
            Username: username,
            Password: password,
        };
        environment = getEnvironment.get();
        userPoolId = environment.elements1_userpoolid;
        clientId = environment.elements1_clientid;
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        poolData = {
            UserPoolId: (userPoolId !== null && userPoolId !== undefined) ? userPoolId : "USERPOOL_NOT_SET",
            ClientId: (clientId !== null && clientId !== undefined) ? clientId : "CLIENTID_NOT_SET"
        };
        userPool = new CognitoUserPool(poolData);
        const userData = {
            Username: username,
            Pool: userPool,
        };
        userPool = new CognitoUserPool(poolData);
        currentUser = getCognitoUser(username, userData);
        console.log('userData.Pool', userData.Pool);
        console.log('userData.Username', userData.Username);
        console.log('currentUser', currentUser);
        currentUser.authenticateUser(authenticationDetails, {
            onSuccess: function (res) {
                resolve(res);
            },
            onFailure: function (err) {
                reject(err);
            },
        });
    }).catch(err => {
        throw err;
    });
});
export { signInWithEmail, getSession, getAttributes };
