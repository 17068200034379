import React from 'react';
import { hot } from 'react-hot-loader';
import StepsTrackerContainer from "./StepsTrackerContainer";
const Step = (props) => {
    const { title, description, isComplete, } = props.step;
    const { currentIndex, steps, onNextStep, onPreviousStep } = props;
    const submit = (e) => {
        e.preventDefault();
        onNextStep();
    };
    const canGoBack = currentIndex > 0 && steps[currentIndex - 1].type === "manual";
    return (React.createElement("div", { className: "onboarding-step" },
        React.createElement("h1", { className: "onboarding-step__title" }, title),
        React.createElement("p", { className: "onboarding-step__description" }, description),
        (currentIndex < steps.length - 1)
            ? React.createElement(StepsTrackerContainer, null)
            : null,
        React.createElement("form", { onSubmit: e => submit(e) },
            props.children,
            currentIndex < steps.length - 1
                ? React.createElement("section", null,
                    React.createElement("iris-button", { disabled: !isComplete, type: "submit", class: "next-btn" }, "Next"),
                    canGoBack
                        ? React.createElement("a", { className: "onboarding-step__link", onClick: onPreviousStep, onKeyDown: onPreviousStep }, `Go back to '${steps[currentIndex - 1].header}'`)
                        : null)
                : null)));
};
export default hot(module)(Step);
