import generateStore from "./storeGenerator";
const dataReducer = (state = generateStore().data, action = { type: "" }) => {
    switch (action.type) {
        case "SET_VALUE":
            const value = (typeof action.value === 'string' || action.value instanceof String) ? action.value.replace(/'/g, '') : action.value;
            return Object.assign(Object.assign({}, state), { [action.key]: value });
        default:
            return state;
    }
};
export default dataReducer;
