export let environment = {
    production: false,
    testing: false,
    retryScalingDuration: 10000,
    region: 'eu-west-2',
    lookup_address_api_uri: 'https://api.iris-elements-development.co.uk/lookup',
    lookup_address_api_key: 'KEedBEmWum2gw0SI8Md1majRPT5sP1HO4Y5FOYG8',
    lookup_countries_api_uri: 'https://api.iris-elements-development.co.uk/lookup-countries/v1',
    lookup_countries_api_key: 'kMvjY0sOih3JeY6eMwehV5Au9Vzh7PGu6hlDMGpE',
    marketplace_hub_api_uri: 'https://6o290cjyff.execute-api.eu-west-2.amazonaws.com/v1',
    marketplace_hub_api_key: 'wv1djYIg5fLk3hC1R7xGaSYbBYVWmZu8rNTs6M31',
    elements2_platform_api_uri: 'https://api.irisws-development.co.uk/platform2/v1',
    elements2_platform_api_key: 'Ecp1U1hWOl3mr8FJf5ELW2UNJngGbzik3NoTp9zd',
    ias_hub_api_uri: 'https://api.iris-elements-development.co.uk/ias/v1',
    ias_hub_api_key: 'w47qAGIH3d27Bu2S01prc4jTojkR4Oy38CIPvMVR',
    elements2_okta_issuer: 'https://identity-dev.iris.co.uk',
    elements2_okta_client_id: '0oa1q8jb1sYUhg5an0x7',
    elements1_userpoolid: 'eu-west-2_KBjcRuuDv',
    elements1_clientid: '4f4k8k0eqc8h4ofc023sadfthf'
};
export const setEnvironment = value => environment = value || environment;
