import developmentFeatures from './features.development.json';
import testingFeatures from './features.testing.json';
import stagingFeatures from './features.staging.json';
import productionFeatures from './features.production.json';
// To identify the environment onboarding app is running
export const identifyElementsEnvironment = () => {
    const url = window.location.href;
    if (url.includes('localhost')) {
        return 'local';
    }
    else if (url.includes('elements-development')) {
        return 'development';
    }
    else if (url.includes('elements-testing')) {
        return 'testing';
    }
    else if (url.includes('elements-staging')) {
        return 'staging';
    }
    return 'production';
};
// To check if a feature is enabled
export const checkFeatureEnabled = (featureName, featuresList) => {
    const filteredFeatures = featuresList.filter(feature => feature.name === featureName && feature.enabled);
    return filteredFeatures.length > 0;
};
// To get the value of a feature toggle & custom list of features
export const getDevFeatureToggle = (featureName) => {
    const environment = identifyElementsEnvironment();
    const featureMapping = {
        local: developmentFeatures,
        development: developmentFeatures,
        testing: testingFeatures,
        staging: stagingFeatures,
        production: productionFeatures,
    };
    const featureList = featureMapping[environment];
    return checkFeatureEnabled(featureName, featureList);
};
