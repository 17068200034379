import generateStore from "./storeGenerator";
import processBuilder from "../processes/processBuilder";
const updateCurrentProcess = (state, processReducer) => {
    return Object.assign(Object.assign({}, state), { processes: state.processes.map(process => {
            if (process.id === state.currentProcessId) {
                return processReducer(process);
            }
            return process;
        }) });
};
const setState = (state, status) => {
    return updateCurrentProcess(state, process => (Object.assign(Object.assign({}, process), { steps: process.steps.map((step, index) => {
            if (index === process.currentStepIndex && step.type === "automatic") {
                return Object.assign(Object.assign({}, step), { status });
            }
            return step;
        }) })));
};
const processReducer = (state = generateStore().process, action = { type: "" }) => {
    switch (action.type) {
        case "SET_PROCESS":
            return Object.assign(Object.assign({}, state), { currentProcessId: action.id });
        case "GOTO_STEP":
            return updateCurrentProcess(state, process => {
                const requestedStep = process.steps.filter(x => x.id === action.id)[0];
                if (!requestedStep) {
                    return process;
                }
                const index = process.steps.indexOf(requestedStep);
                return Object.assign(Object.assign({}, process), { currentStepIndex: index });
            });
        case "NEXT_STEP":
            return updateCurrentProcess(state, process => {
                if (process.steps.length > process.currentStepIndex + 1) {
                    return Object.assign(Object.assign({}, process), { currentStepIndex: (process.currentStepIndex + 1) });
                }
                return process;
            });
        case "PREVIOUS_STEP":
            return updateCurrentProcess(state, process => {
                if (process.currentStepIndex > 0) {
                    return Object.assign(Object.assign({}, process), { currentStepIndex: (process.currentStepIndex - 1), steps: process.steps.map((step, index) => {
                            if (index === process.currentStepIndex && step.type === "automatic") {
                                return Object.assign(Object.assign({}, step), { status: processBuilder.getInitialStep(process.steps[process.currentStepIndex].id).status });
                            }
                            return step;
                        }) });
                }
                return process;
            });
        case "SET_STEP_STATUS":
            return setState(state, action.status);
        default:
            return state;
    }
};
export default processReducer;
