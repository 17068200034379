import React from 'react';
import Checkbox from '../../components/Checkbox';
import ValidationError from "../../components/common/ValidationError";
import newUserStep1Validation from "../../validation/newUserProfileValidation";
const schema = newUserStep1Validation.create();
const UserDetails = (props) => {
    const { setValue } = props;
    const { email, firstName, lastName, contactAboutFeatures, terms } = props.data;
    const validationResult = schema.validate(props.data, { abortEarly: false });
    const validationError = (fieldName) => {
        var _a;
        if (Object.keys(props.data).indexOf(fieldName) < 0) {
            return "";
        }
        const error = (_a = validationResult.error) === null || _a === void 0 ? void 0 : _a.details.filter(x => { var _a; return ((_a = x.context) === null || _a === void 0 ? void 0 : _a.key) === fieldName; })[0];
        if (error) {
            return error.message;
        }
        return "";
    };
    function clickHandler(e) {
        const el = e.target.closest("a");
        if (el && e.currentTarget.contains(el)) {
            return e.stopPropagation();
        }
        else {
            setValue("terms", !terms);
        }
    }
    return (React.createElement("div", null,
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "email" },
                "Email address",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "email", type: "email", id: "email", value: email ? email : '', disabled: !props.isNewSignUp, onChange: e => setValue("email", e.target.value), className: validationError("email").length > 0 ? 'validation-error' : '' }),
            validationError("email") && React.createElement(ValidationError, { validationError: validationError("email") })),
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "firstName" },
                "First name",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "firstName", type: "text", id: "firstName", value: firstName ? firstName : '', onChange: e => setValue("firstName", e.target.value.replace(/['`]/g, "")), className: validationError("firstName").length > 0 ? 'validation-error' : '' }),
            validationError("firstName") && React.createElement(ValidationError, { validationError: validationError("firstName") })),
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "lastName" },
                "Last name",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "lastName", type: "text", id: "lastName", value: lastName ? lastName : '', onChange: e => setValue("lastName", e.target.value.replace(/['`]/g, "")), className: validationError("lastName").length > 0 ? 'validation-error' : '' }),
            validationError("lastName") && React.createElement(ValidationError, { validationError: validationError("lastName") })),
        React.createElement("section", null,
            React.createElement(Checkbox, { checked: contactAboutFeatures, id: "contactAboutFeatures", label: " I am happy to be contacted about early access to new features.", onClick: () => setValue("contactAboutFeatures", !contactAboutFeatures) }),
            validationError("contactAboutFeatures") && React.createElement(ValidationError, { validationError: validationError("contactAboutFeatures") })),
        React.createElement("section", null,
            React.createElement(Checkbox, { checked: terms, id: "terms", label: '<span>I agree to the </span> <a href="https://www.iris.co.uk/terms-conditions/" target="_blank">terms of use</a><span class="asterisk">*</span>', onClick: clickHandler }),
            validationError("terms") && React.createElement(ValidationError, { validationError: validationError("terms") }))));
};
export default UserDetails;
