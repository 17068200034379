import { connect } from "react-redux";
import { setProcess } from '../../store/actionCreators';
import { ProcessSelector } from "./ProcessSelector";
const mapStateToProps = (state) => ({
    processState: state.process,
});
const mapDispatchToProps = (dispatch) => {
    return {
        setProcess: (id) => dispatch(setProcess(id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProcessSelector);
