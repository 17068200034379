import React from 'react';
import { hot } from 'react-hot-loader';
const ConfirmationStep = (props) => {
    const { title, description } = props.step;
    const submit = (e) => {
        e.preventDefault();
        props.submit();
    };
    return (React.createElement("div", { className: "onboarding-step" },
        React.createElement("h1", { className: "onboarding-step__title" }, title),
        React.createElement("p", { className: "onboarding-step__description" }, description),
        React.createElement("form", { onSubmit: e => submit(e) }, props.children)));
};
export default hot(module)(ConfirmationStep);
