import React from 'react';
import { hot } from 'react-hot-loader';
const VerificationEmail = () => {
    return (React.createElement("div", { style: { display: "contents" } },
        React.createElement("br", null),
        React.createElement("svg", { width: "215", height: "200", viewBox: "0 0 215 200", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("rect", { x: "0.5", width: "200", height: "200", rx: "100", fill: "#F7F8FA" }),
            React.createElement("path", { opacity: "0.05", d: "M98.6973 198.247C101.838 195.586 103.746 191.381 103.246 187.288C102.746 183.195 99.6465 179.432 95.6266 178.563C91.6066 177.694 87.0241 180.1 85.8031 184.037C85.1311 176.446 84.3568 168.535 80.3316 162.07C76.6869 156.216 70.3742 152.027 63.5326 151.278C56.6911 150.528 49.4878 153.359 45.221 158.774C40.9541 164.189 39.9094 172.061 42.8978 178.278C45.0992 182.857 49.155 186.266 53.4045 189.039C67.1361 197.923 83.6054 201.536 99.7821 199.213", fill: "#24303B" }),
            React.createElement("path", { d: "M51.9853 153.516C57.6305 158.126 62.8093 163.283 67.4469 168.912C74.7047 177.69 80.5778 187.531 84.8615 198.094C85.1751 198.865 83.9273 199.2 83.6172 198.438C80.8931 191.76 77.5227 185.365 73.5546 179.346C67.3668 169.944 59.7858 161.542 51.0729 154.431C50.4287 153.905 51.3464 152.994 51.9853 153.516V153.516Z", fill: "white" }),
            React.createElement("path", { opacity: "0.05", d: "M100.864 198.247C97.7233 195.586 95.8153 191.381 96.3157 187.288C96.816 183.195 99.9151 179.432 103.935 178.563C107.955 177.694 112.538 180.1 113.759 184.037C114.431 176.446 115.205 168.535 119.23 162.07C122.875 156.216 129.187 152.027 136.029 151.278C142.871 150.528 150.074 153.359 154.341 158.774C158.608 164.189 159.652 172.061 156.664 178.278C154.462 182.857 150.407 186.266 146.157 189.039C132.426 197.923 115.956 201.536 99.7796 199.213", fill: "#24303B" }),
            React.createElement("path", { d: "M147.577 153.516C141.932 158.126 136.753 163.283 132.115 168.912C124.857 177.69 118.984 187.531 114.701 198.094C114.387 198.865 115.635 199.2 115.945 198.438C122.954 181.271 134.143 166.141 148.489 154.431C149.133 153.905 148.216 152.994 147.577 153.516V153.516Z", fill: "white" }),
            React.createElement("path", { opacity: "0.05", d: "M110.186 107.593C115.997 107.593 120.708 102.87 120.708 97.0425C120.708 91.2153 115.997 86.4915 110.186 86.4915C104.375 86.4915 99.6641 91.2153 99.6641 97.0425C99.6641 102.87 104.375 107.593 110.186 107.593Z", fill: "#24303B" }),
            React.createElement("path", { opacity: "0.05", d: "M77.7469 60.6714H150.239V64.6485H87.0012C83.283 64.6485 79.6013 65.3829 76.1661 66.8097C72.731 68.2366 69.6098 70.3279 66.9806 72.9643C64.3515 75.6007 62.266 78.7306 60.8431 82.1753C59.4202 85.62 58.6879 89.312 58.6879 93.0404V138.226H50.7556C50.405 138.226 50.0687 138.086 49.8208 137.837C49.5729 137.589 49.4336 137.251 49.4336 136.9V89.0633C49.4336 85.3348 50.1659 81.6428 51.5888 78.1982C53.0117 74.7535 55.0972 71.6236 57.7264 68.9872C60.3555 66.3507 63.4767 64.2594 66.9119 62.8326C70.347 61.4058 74.0287 60.6714 77.7469 60.6714V60.6714Z", fill: "#24303B" }),
            React.createElement("path", { d: "M91.6895 198.468V138.055H109.339V198.468C109.338 198.874 109.177 199.264 108.891 199.551C108.605 199.838 108.216 199.999 107.812 200H93.2168C92.8119 199.999 92.4236 199.838 92.1373 199.551C91.851 199.264 91.6899 198.874 91.6895 198.468Z", fill: "#A7ACB1" }),
            React.createElement("path", { d: "M45.6992 136.864V87.739C45.708 79.9186 48.81 72.4211 54.3245 66.8912C59.839 61.3614 67.3158 58.2508 75.1145 58.2419H149.899V138.566H47.3963C46.9464 138.565 46.515 138.386 46.1969 138.067C45.8787 137.748 45.6997 137.315 45.6992 136.864V136.864ZM149.22 58.9227H75.1145C67.4958 58.9313 60.1915 61.9701 54.8043 67.3723C49.417 72.7746 46.3867 80.0991 46.378 87.739V136.864C46.3783 137.135 46.4857 137.394 46.6766 137.586C46.8675 137.777 47.1263 137.885 47.3963 137.885H149.22V58.9227Z", fill: "#A7ACB1" }),
            React.createElement("path", { d: "M110.187 102.488C108.567 102.486 107.014 101.84 105.869 100.692C104.724 99.5433 104.079 97.9861 104.078 96.3618V52.456C104.077 52.1853 103.97 51.9257 103.779 51.7343C103.588 51.5429 103.329 51.4352 103.059 51.4349H79.3006C78.8506 51.4344 78.4193 51.2549 78.1012 50.9359C77.783 50.6169 77.604 50.1843 77.6035 49.7331V33.7364C77.604 33.2853 77.783 32.8527 78.1012 32.5337C78.4193 32.2147 78.8506 32.0352 79.3006 32.0347H114.599C115.049 32.0352 115.481 32.2146 115.799 32.5337C116.117 32.8527 116.296 33.2853 116.297 33.7364V96.3618C116.295 97.9861 115.65 99.5433 114.505 100.692C113.36 101.84 111.807 102.486 110.187 102.488Z", fill: "#1B69B9" }),
            React.createElement("path", { d: "M150.733 58.0086C155.893 57.9979 160.846 60.0151 164.502 63.6164C168.158 67.2177 170.219 72.1082 170.229 77.212V132.801H208.538C210.077 132.798 211.555 133.399 212.645 134.473C213.736 135.547 214.35 137.006 214.353 138.528H131.236V77.212C131.247 72.1082 133.307 67.2177 136.964 63.6164C140.62 60.0151 145.573 57.9979 150.733 58.0086Z", fill: "#8F959B" }),
            React.createElement("path", { d: "M110.188 98.7442C111.501 98.7442 112.564 97.6775 112.564 96.3617C112.564 95.0459 111.501 93.9792 110.188 93.9792C108.876 93.9792 107.812 95.0459 107.812 96.3617C107.812 97.6775 108.876 98.7442 110.188 98.7442Z", fill: "white" }),
            React.createElement("path", { d: "M138.207 132.364C138.207 132.125 138.401 131.931 138.64 131.931L170.233 131.934V132.796L138.64 132.797C138.401 132.797 138.207 132.603 138.207 132.364Z", fill: "#F7F8FA" }),
            React.createElement("path", { opacity: "0.1", d: "M77.4453 49.0905C77.4453 47.7152 78.5602 46.6003 79.9354 46.6003H97.4388C98.8141 46.6003 99.9289 47.7152 99.9289 49.0905V49.0905C99.9289 50.4657 98.8141 51.5806 97.4388 51.5806H79.9354C78.5602 51.5806 77.4453 50.4657 77.4453 49.0905V49.0905Z", fill: "black" })),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("p", { className: "onboarding-step__email-message" }, "Check your inbox and click the link provided in our email to activate your new IRIS Elements account. If you have not received anything, check your spam folder or resend link using the button below."),
        React.createElement("section", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-6" },
                    React.createElement("iris-button", { id: "resend-link", type: "submit" }, "Resend link")),
                React.createElement("div", { className: "col-6 onboarding-step__contact-support" },
                    React.createElement("iris-button", { colour: "secondary", className: "onboarding-step__contact-support-button", onClick: e => { e.preventDefault(); window.open("mailto:identitysupport@iris.co.uk"); } }, "Contact support"))))));
};
export default hot(module)(VerificationEmail);
