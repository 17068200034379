import { combineReducers } from 'redux';
import generateStore from "./storeGenerator";
import componentReducer from "./componentReducer";
import processReducer from './processReducer';
import dataReducer from "./dataReducer";
import dataStore from "../localStorage/dataStore";
const rootReducer = combineReducers({
    components: componentReducer,
    process: processReducer,
    data: dataReducer
});
const updateCurrentProcess = (state, processReducer) => {
    return Object.assign(Object.assign({}, state), { processes: state.processes.map(process => {
            if (process.id === state.currentProcessId) {
                return processReducer(process);
            }
            return process;
        }) });
};
const mapStep = (step, data) => {
    switch (step.type) {
        case "manual":
            const validationError = step.validate(data);
            return Object.assign(Object.assign({}, step), { isComplete: !validationError, validationError });
        case "new-user-manual":
            const newUserValidationError = step.validate(data);
            return Object.assign(Object.assign({}, step), { isComplete: !newUserValidationError, validationError: newUserValidationError });
        default:
            return step;
    }
};
const verifySteps = (state) => {
    return Object.assign(Object.assign({}, state), { process: Object.assign(Object.assign({}, state.process), { processes: state.process.processes.map(process => {
                if (process.id === state.process.currentProcessId) {
                    return Object.assign(Object.assign({}, process), { steps: process.steps.map(step => mapStep(step, state.data)) });
                }
                return process;
            }) }) });
};
export default (state = generateStore(), action = { type: "" }) => {
    const newState = rootReducer(state, action);
    dataStore.save(newState.data);
    return verifySteps(newState);
};
