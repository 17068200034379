import localStore from "./localStore";
const jsonStore = (key) => {
    const store = localStore(key);
    return {
        get: () => JSON.parse(store.get()),
        save: (data) => store.save(JSON.stringify(data)),
        delete: () => store.delete()
    };
};
export default jsonStore;
