import React from 'react';
import ReactDOM from 'react-dom';
require('es6-promise').polyfill();
require('isomorphic-fetch');
import Default from './routes/Default';
import '../scss/app.scss';
import setEnvironment from './setEnvironment';
(setEnvironment)();
ReactDOM.render(React.createElement("div", { id: "react-app-onboarding" },
    React.createElement(Default, null)), document.getElementById('App'));
