var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const setValue = (key, value) => {
    return ({ type: "SET_VALUE", key, value });
};
const nextStep = () => {
    return ({ type: "NEXT_STEP" });
};
const previousStep = () => {
    return ({ type: "PREVIOUS_STEP" });
};
const gotoStep = (id) => {
    return ({ type: "GOTO_STEP", id });
};
const setStepStatus = (status) => {
    return ({ type: "SET_STEP_STATUS", status });
};
const setProcess = (id) => {
    return ({ type: "SET_PROCESS", id });
};
const setLocation = (url) => {
    return ({ type: "SET_LOCATION", url });
};
const handleSuccess = (dispatch) => {
    dispatch(setStepStatus("success"));
    dispatch({ type: "NEXT_STEP" });
};
const handleFailure = (dispatch, error) => {
    dispatch(setStepStatus("failed"));
    if ((error == null) || ((error == undefined))) {
        error = "Error occurred, please try again";
    }
    dispatch({ type: "SET_ERROR", message: error });
};
const submit = (promise) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            dispatch(setStepStatus("processing"));
            const result = yield promise;
            if (result.state === "success") {
                handleSuccess(dispatch);
            }
            else {
                handleFailure(dispatch, result.message);
            }
        }
        catch (error) {
            handleFailure(dispatch, error);
        }
    });
};
export { nextStep, previousStep, gotoStep, submit, setValue, setStepStatus, setProcess };
