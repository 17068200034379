import Joi from "joi";
import { validation } from "../utils/validation.util";
const validatePhone = (value, helper) => {
    if (!validation.isTelephoneNumberValid(value)) {
        return helper.message("{{#label}} must be a valid telephone number");
    }
    else {
        return true;
    }
};
const validateAddress = (address, helper) => {
    if (!validation.isAddressValid(address)) {
        return helper.message("{{#label}} must be a valid address");
    }
    else {
        return true;
    }
};
const create = () => {
    return Joi.object({
        businessSize: Joi.string()
            .required()
            .label("Business size"),
        businessName: Joi.string().trim()
            .max(1)
            .max(49)
            .required()
            .label("Business name"),
        businessTelephoneNumber: Joi.string().trim()
            .custom(validatePhone)
            .required()
            .label("Business Telephone Number"),
        businessAddress: Joi.object()
            .custom(validateAddress)
            .required()
            .label("Business Address"),
    });
};
export default { create };
