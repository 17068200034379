import React from 'react';
import { hot } from 'react-hot-loader';
import { FiUser, FiUsers, FiArrowRight } from "react-icons/fi";
import { BiBuildings } from "react-icons/bi";
import { AiOutlineMail, AiFillLock } from "react-icons/ai";
const StepIconSelect = (props) => {
    switch (props.stepId) {
        case "new-user-1":
            return React.createElement(FiUser, null);
        case "new-user-2":
            return React.createElement(BiBuildings, null);
        case "new-user-4":
            return React.createElement(AiOutlineMail, null);
        case "new-tax-user-1":
            return React.createElement(FiUser, null);
        case "new-tax-user-2":
            return React.createElement(BiBuildings, null);
        case "new-tax-user-4":
            return React.createElement(AiOutlineMail, null);
        case "elements1-user-1":
            return React.createElement(AiFillLock, null);
        case "elements1-user-2":
            return React.createElement(FiUser, null);
        case "elements1-user-3":
            return React.createElement(BiBuildings, null);
        case "elements1-user-4":
            return React.createElement(FiUsers, null);
        case "elements1-user-6":
            return React.createElement(AiOutlineMail, null);
        default:
            return React.createElement(FiArrowRight, null);
    }
};
export default hot(module)(StepIconSelect);
