import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import googleTrackingService from "../services/googleTrackingService";
const GoogleTracking = () => {
    const location = useLocation();
    useEffect(() => {
        googleTrackingService.trackPage(location.pathname, location.search);
    }, [location]);
    return null;
};
export default GoogleTracking;
