import Joi from "joi";
const create = () => {
    return Joi.object({
        email: Joi.string()
            .max(100)
            .email({ tlds: { allow: false } })
            .required(),
        password: Joi.string()
            .required()
            .label("Password"),
    });
};
export default { create };
