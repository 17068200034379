import httpService from "../../services/newUserService";
import taxAndAccountsHttpService from "../../services/taxAndAccountsUserService";
import migrationUserService from "../../services/migrationUserService";
import newUserProfileValidation from "../../validation/newUserProfileValidation";
import elements1UsersValidation from "../../validation/elements1UsersValidation";
import newUserBusinessValidation from "../../validation/newUserBusinessValidation";
import cognitoLoginValidation from "../../validation/cognitoLoginValidation";
import newUserDetailsProfileValidation from "../../validation/newUserDetailsProfileValidation";
import { getDevFeatureToggle } from "../../features-dev";
const options = {
    allowUnknown: true,
    abortEarly: false
};
const processBuilder = {
    build: () => [
        {
            id: "NEW_USER",
            currentStepIndex: 0,
            steps: getDevFeatureToggle('UseCondenseOnboarding') ? [
                {
                    type: "new-user-manual",
                    id: "condensed-user",
                    header: "Complete profile",
                    title: "Welcome to IRIS Elements",
                    description: "Sign up now, it’s FAST and FREE!",
                    url: "/complete-profile",
                    isComplete: false,
                    schema: newUserDetailsProfileValidation.create(),
                    validate: (data) => newUserDetailsProfileValidation.create().validate(data, options).error,
                },
                {
                    type: "automatic",
                    id: "new-user-3",
                    header: "Create profile",
                    title: "Signing up to Elements",
                    description: "Creating your Elements profile",
                    url: "/submitting",
                    status: "pending",
                    process: httpService.submitAsync
                },
                {
                    type: "confirmation",
                    id: "new-user-4",
                    header: "Verify email",
                    title: "Thanks, you're almost set up!",
                    description: "We've sent a verification to the email address you provided earlier.",
                    url: "/email-verification",
                    status: "success",
                    process: httpService.submitAsync
                }
            ] : [
                {
                    type: "manual",
                    id: "new-user-1",
                    header: "Complete profile",
                    title: "Welcome to IRIS Elements",
                    description: "Let's get started! First complete the details below to create your profile",
                    url: "/complete-profile",
                    isComplete: false,
                    schema: newUserProfileValidation.create(),
                    validate: (data) => newUserProfileValidation.create().validate(data, options).error
                },
                {
                    type: "manual",
                    id: "new-user-2",
                    header: "Your business",
                    title: "Tell us about your business",
                    description: "Complete the details below to personalise IRIS Elements",
                    url: "/your-business",
                    isComplete: false,
                    schema: newUserBusinessValidation.create(),
                    validate: (data) => newUserBusinessValidation.create().validate(data, options).error
                },
                {
                    type: "automatic",
                    id: "new-user-3",
                    header: "Create profile",
                    title: "Signing up to Elements",
                    description: "Creating your Elements profile",
                    url: "/submitting",
                    status: "pending",
                    process: httpService.submitAsync
                },
                {
                    type: "confirmation",
                    id: "new-user-4",
                    header: "Verify email",
                    title: "Thanks, you're almost set up!",
                    description: "We've sent a verification to the email address you provided earlier.",
                    url: "/email-verification",
                    status: "success",
                    process: httpService.submitAsync
                }
            ],
        },
        {
            id: "TAX_AND_ACCOUNTS_USER",
            currentStepIndex: 0,
            steps: [
                {
                    type: "manual",
                    id: "new-tax-user-1",
                    header: "Complete profile",
                    title: "Welcome to IRIS Elements",
                    description: "Let's get started! First complete the details below to create your profile",
                    url: "/migration/tax-and-accounts/complete-profile",
                    isComplete: false,
                    schema: newUserProfileValidation.create(),
                    validate: (data) => newUserProfileValidation.create().validate(data, options).error
                },
                {
                    type: "manual",
                    id: "new-tax-user-2",
                    header: "Your business",
                    title: "Tell us about your business",
                    description: "Complete the details below to personalise IRIS Elements",
                    url: "/migration/tax-and-accounts/your-business",
                    isComplete: false,
                    schema: newUserBusinessValidation.create(),
                    validate: (data) => newUserBusinessValidation.create().validate(data, options).error
                },
                {
                    type: "automatic",
                    id: "new-tax-user-3",
                    header: "Create profile",
                    title: "Signing up to Elements",
                    description: "Creating your Elements profile",
                    url: "/submitting",
                    status: "pending",
                    process: taxAndAccountsHttpService.taxAndAccountSubmitAsync
                },
                {
                    type: "confirmation",
                    id: "new-tax-user-4",
                    header: "Verify email",
                    title: "Thanks, you're almost set up!",
                    description: "We've sent a verification to the email address you provided earlier.",
                    url: "/email-verification",
                    status: "success",
                    process: taxAndAccountsHttpService.taxAndAccountSubmitAsync
                }
            ],
        },
        {
            id: "ELEMENTS1_USER",
            currentStepIndex: 0,
            steps: [
                {
                    type: "manual",
                    id: "elements1-user-1",
                    header: "Login to your existing account",
                    title: "Login to your existing account",
                    description: "Log into your existing Elements account so we can get your details",
                    url: "/migration/login",
                    isComplete: false,
                    schema: cognitoLoginValidation.create(),
                    validate: (data) => cognitoLoginValidation.create().validate(data, options).error
                },
                {
                    type: "automatic",
                    id: "elements1-user-1b",
                    header: "Signing in to your existing account",
                    title: "Signing in to your existing account",
                    description: "Fetching your existing Elements account details",
                    url: "/migration/authenticating",
                    status: "pending",
                    process: migrationUserService.loginAsync
                },
                {
                    type: "manual",
                    id: "elements1-user-2",
                    header: "Complete profile",
                    title: "Welcome to IRIS Elements",
                    description: "First complete the details below to create your profile",
                    url: "/migration/complete-profile",
                    isComplete: false,
                    schema: newUserProfileValidation.create(),
                    validate: (data) => newUserProfileValidation.create().validate(data, options).error
                },
                {
                    type: "manual",
                    id: "elements1-user-3",
                    header: "Your business",
                    title: "Tell us about your business",
                    description: "Complete the details below to personalise IRIS Elements",
                    url: "/migration/your-business",
                    isComplete: false,
                    schema: newUserBusinessValidation.create(),
                    validate: (data) => newUserBusinessValidation.create().validate(data, options).error
                },
                {
                    type: "manual",
                    id: "elements1-user-4",
                    header: "Your users",
                    title: "Migrate your existing users",
                    description: "Confirm which users you want to migrate",
                    url: "/migration/your-users",
                    isComplete: false,
                    schema: newUserProfileValidation.create(),
                    validate: (data) => elements1UsersValidation.create().validate(data, options).error
                },
                {
                    type: "automatic",
                    id: "elements1-user-5",
                    header: "Create profile",
                    title: "Migrating to Elements",
                    description: "Creating your Elements profile",
                    url: "/submitting",
                    status: "pending",
                    process: migrationUserService.submitAsync
                },
                {
                    type: "confirmation",
                    id: "elements1-user-6",
                    header: "Verify email",
                    title: "Thanks, you're almost set up!",
                    description: "We've sent a verification to the email address you provided earlier.",
                    url: "/email-verification",
                    status: "success",
                    process: httpService.submitAsync
                }
            ],
        }
    ],
    getInitialStep: (id) => {
        return processBuilder.build().reduce((accumulator, currentValue) => [...accumulator, ...currentValue.steps], []).find(item => item.id === id);
    }
};
export default processBuilder;
