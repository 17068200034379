import React from 'react';
import ValidationError from "../../components/common/ValidationError";
import newUserStep1Validation from "../../validation/cognitoLoginValidation";
import { MaskedPassword } from "../common/MaskedPassword";
import { toLowerCaseString } from "../../utils/string.util";
const schema = newUserStep1Validation.create();
const CognitoLogin = (props) => {
    const { setValue } = props;
    const { email, password, } = props.data;
    const validationResult = schema.validate(props.data, { abortEarly: false });
    const updatePasswordValue = value => setValue("password", value);
    const validationError = (fieldName) => {
        var _a;
        if (Object.keys(props.data).indexOf(fieldName) < 0) {
            return "";
        }
        const error = (_a = validationResult.error) === null || _a === void 0 ? void 0 : _a.details.filter(x => { var _a; return ((_a = x.context) === null || _a === void 0 ? void 0 : _a.key) === fieldName; })[0];
        if (error) {
            return error.message;
        }
        return "";
    };
    return (React.createElement("div", null,
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "email" },
                "Email address",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "email", type: "email", id: "email", value: email ? toLowerCaseString(email) : '', onChange: e => setValue("email", toLowerCaseString(e.target.value)), className: validationError("email").length > 0 ? 'validation-error' : 'lowercase' }),
            validationError("email") && React.createElement(ValidationError, { validationError: validationError("email") })),
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "password" },
                "Password",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement(MaskedPassword, { masked: true, error: false, label: "Password", name: "password", id: "password", value: password ? password : '', onUpdates: updatePasswordValue, cssClass: validationError("password").length > 0 ? 'validation-error' : '' }),
            validationError("password") && React.createElement(ValidationError, { validationError: validationError("password") }))));
};
export default CognitoLogin;
