import { connect } from "react-redux";
import { Process } from "./Process";
import { gotoStep, nextStep, previousStep, setValue, submit } from '../../store/actionCreators';
import { getCurrentProcess } from "../../store/helpers/storeHelpers";
const mapStateToProps = (state) => {
    const process = getCurrentProcess(state);
    return {
        processState: state.process,
        currentProcess: process,
        currentStep: process.steps[process.currentStepIndex],
        currentIndex: process.currentStepIndex,
        data: state.data,
        state: state
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setValue: (key, value) => dispatch(setValue(key, value)),
        onNextStep: () => dispatch(nextStep()),
        onPreviousStep: () => dispatch(previousStep()),
        onGotoStep: (id) => dispatch(gotoStep(id)),
        submit: (promise) => dispatch(submit(promise)),
        dispatch
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Process);
