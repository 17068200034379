// @ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import getEnvironment from '../getEnvironment';
// components
import Header from '../components/Header';
// routes
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from '../store/reducer';
// UI KIT
import { applyPolyfills, defineCustomElements } from '@iris/platform-ui-kit/loader';
import JoinElements from './JoinElements';
import GoogleTracking from './GoogleTracking';
applyPolyfills().then(() => {
    defineCustomElements();
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));
const Default = () => {
    const environment = getEnvironment.get();
    const oktaAuth = new OktaAuth({
        issuer: `${environment.elements2_okta_issuer}/oauth2/default`,
        redirectUri: window.location.origin + '/logincallback',
        clientId: environment.elements2_okta_client_id,
        pkce: true,
        scopes: ['openid', 'profile'],
    });
    const restoreOriginalUri = (_oktaAuth, originalUri) => __awaiter(void 0, void 0, void 0, function* () {
        history.replace(toRelativeUrl(originalUri, window.location.toString()));
    });
    return React.createElement(Provider, { store: store },
        React.createElement("div", { className: "onboarding-default" },
            React.createElement(Header, null),
            React.createElement(Router, null,
                React.createElement(GoogleTracking, null),
                React.createElement(Security, { oktaAuth: oktaAuth, restoreOriginalUri: restoreOriginalUri },
                    React.createElement(Switch, null,
                        React.createElement(Route, { exact: true, path: "/", render: () => React.createElement(Redirect, { to: "/complete-profile" }) }),
                        React.createElement(SecureRoute, { path: "/login", component: JoinElements }),
                        React.createElement(Route, { exact: true, path: "/logincallback", component: LoginCallback }),
                        React.createElement(Route, { path: "*", component: JoinElements }))))));
};
export default hot(module)(Default);
