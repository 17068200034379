import React from 'react';
import { hot } from 'react-hot-loader';
const checkbox = (props) => {
    return (React.createElement("div", { className: "iris-checkbox iris-checkbox--vertical", onClick: e => props.onClick(e), onKeyDown: e => props.onClick(e) },
        React.createElement("input", { defaultChecked: props.checked, type: "checkbox", name: "terms", "aria-label": "terms", id: props.id }),
        React.createElement("label", { htmlFor: "terms" },
            React.createElement("span", { dangerouslySetInnerHTML: { __html: props.label } })),
        React.createElement("i", { className: "icon" },
            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", role: "img", "aria-label": "Check icon", focusable: "false", className: "feather feather-check" },
                React.createElement("polyline", { points: "20 6 9 17 4 12" })))));
};
export default hot(module)(checkbox);
