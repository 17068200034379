/**
 * Converts all uppercase letters in the input to lowercase.
 *
 * @param input - The value to be transformed.
 * @returns The transformed string with all lowercase letters.
 */
const toLowerCaseString = (input) => {
    if (input == null) {
        console.log("value is either undefined or null");
        return '';
    }
    return input.toString().toLocaleLowerCase();
};
export { toLowerCaseString };
