import Joi from "joi";
import { validateAddress, validateName, validatePhone } from "../utils/validation.util";
const create = () => {
    return Joi.object({
        email: Joi.string()
            .max(100)
            .email({ tlds: { allow: false } })
            .required(),
        firstName: Joi.string().trim()
            .max(1)
            .max(49)
            .custom(validateName)
            .required()
            .label("First name"),
        lastName: Joi.string().trim()
            .max(1)
            .max(49)
            .custom(validateName)
            .required()
            .label("Last name"),
        businessName: Joi.string().trim()
            .max(1)
            .max(49)
            .required()
            .label("Business name"),
        businessTelephoneNumber: Joi.string().trim()
            .custom(validatePhone)
            .required()
            .label("Business Telephone Number"),
        businessAddress: Joi.object()
            .custom(validateAddress)
            .required()
            .label("Business Address"),
    });
};
export default { create, validateName, validatePhone, validateAddress };
