import React from 'react';
import { hot } from 'react-hot-loader';
import ValidationError from "../../components/common/ValidationError";
import elements1UsersValidation from "../../validation/elements1UsersValidation";
const schema = elements1UsersValidation.create();
const Elements1UsersStep = (props) => {
    const { invitedUsers } = props.data;
    const setValue = (index, key, value) => {
        props.setValue("invitedUsers", invitedUsers.map((user, userIndex) => {
            if (userIndex === index) {
                return Object.assign(Object.assign({}, user), { [key]: value });
            }
            return user;
        }));
    };
    const remove = (index) => {
        props.setValue("invitedUsers", invitedUsers.filter((user, userIndex) => userIndex !== index));
    };
    const validationResult = schema.validate(props.data, { abortEarly: false });
    const validationError = (index, fieldName) => {
        var _a;
        if (Object.keys(props.data).indexOf(fieldName) < 0) {
            return "";
        }
        const error = (_a = validationResult.error) === null || _a === void 0 ? void 0 : _a.details.filter(x => x.path[1] === index && x.path[2] === fieldName)[0];
        if (error) {
            return error.message;
        }
        return "";
    };
    return (React.createElement("div", null,
        !invitedUsers.length &&
            React.createElement("section", { className: "notification" },
                React.createElement("label", { htmlFor: "businessName" }, "No additional users are available for invite.")),
        invitedUsers.map((user, index) => React.createElement("div", { key: user.email },
            React.createElement("h4", null, user.email),
            React.createElement("section", null,
                React.createElement("label", { htmlFor: "firstName" + index },
                    "First name",
                    React.createElement("span", { className: "asterisk" }, "*")),
                React.createElement("input", { name: "firstName", type: "text", id: "firstName" + index, value: user.firstName ? user.firstName : '', onChange: e => setValue(index, "firstName", e.target.value), className: validationError(index, "firstName").length > 0 ? 'validation-error' : '' }),
                validationError(index, "firstName") && React.createElement(ValidationError, { validationError: validationError(index, "firstName") })),
            React.createElement("section", null,
                React.createElement("label", { htmlFor: "lastName" + index },
                    "Last name",
                    React.createElement("span", { className: "asterisk" }, "*")),
                React.createElement("input", { name: "lastName", type: "text", id: "lastName" + index, value: user.lastName ? user.lastName : '', onChange: e => setValue(index, "lastName", e.target.value), className: validationError(index, "lastName").length > 0 ? 'validation-error' : '' }),
                validationError(index, "lastName") && React.createElement(ValidationError, { validationError: validationError(index, "lastName") })),
            React.createElement("iris-button", { class: "remove-btn", id: `remove-btn${index}`, onClick: () => remove(index) }, "Remove")))));
};
export default hot(module)(Elements1UsersStep);
