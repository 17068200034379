var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import getEnvironment from '../getEnvironment';
import { setValue } from '../store/actionCreators';
import idTokenStore from "../store/localStorage/idTokenStore";
import accessTokenStore from "../store/localStorage/accessTokenStore";
import tenantIdStore from "../store/localStorage/tenantIdStore";
import signInCognito from "../helper/getCognito";
import getAttribute from "../helper/getAttributes";
import cognitoSession from "../helper/getSession";
import getUsers from "../helper/getMigration";
import { toLowerCaseString } from "../utils/string.util";
const mapUser = (user) => ({
    email: toLowerCaseString(user.Email),
    firstName: user.GivenName,
    lastName: user.FamilyName,
    elementsOneId: user.SubjectId,
    isEnabled: user.Enabled,
    isAdmin: user.Admin,
    groups: user.Groups,
});
/* istanbul ignore next */
export const loginAsync = (state, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let retval = yield signInCognito.signIn(state.data.email, state.data.password);
        const sessionCurr = yield cognitoSession.current();
        const accessToken = sessionCurr.accessToken.jwtToken;
        const idToken = sessionCurr.idToken.jwtToken;
        accessTokenStore.save(accessToken);
        idTokenStore.save(idToken);
        console.log('sessionCurr.accessToken.jwtToken', sessionCurr.accessToken.jwtToken);
        console.log('sessionCurr.idToken.jwtToken', sessionCurr.idToken.jwtToken);
        console.log('sessionCurr.accessToken', sessionCurr.accessToken);
        console.log('sessionCurr.idToken', sessionCurr.idToken);
        console.log('sessionCurr', sessionCurr);
        console.log('retval', retval);
        const attr = yield getAttribute.gets();
        let attrObj = Object.fromEntries(attr.map(a => [a.Name, a.Value]));
        console.log('attrObj', attrObj);
        const tenants = attrObj["custom:PlatformTenantId"] && JSON.parse(attrObj["custom:PlatformTenantId"]);
        const tenantId = tenants.t[0].id;
        tenantIdStore.save(tenantId);
        console.log('tenantId', tenantId);
        dispatch(setValue("firstName", attrObj.given_name || ""));
        dispatch(setValue("lastName", attrObj.family_name || ""));
        console.log('attrObj.given_name', attrObj.given_name);
        console.log('attrObj.family_name', attrObj.family_name);
        const getUsersResult = yield getUsers.currentUser();
        if (getUsersResult.state !== "success") {
            console.log('not success', getUsersResult.state, getUsersResult.message);
            return getUsersResult;
        }
        const mappedUsers = getUsersResult.payload.Users.filter(user => user && user.Enabled).map(x => mapUser(x));
        const currentUser = mappedUsers.find(x => toLowerCaseString(x.email) == toLowerCaseString(state.data.email));
        console.warn('mappedUsers', mappedUsers);
        console.warn('currentUser', currentUser);
        if (!((currentUser.isEnabled) && (currentUser.isAdmin) && (-1 < currentUser.groups.findIndex(x => x == 'Admin')))) {
            return {
                state: "failed",
                message: "User can not login. Please be aware only the primary user of an account can initiate the migration process."
            };
        }
        dispatch(setValue("invitedUsers", mappedUsers.filter(user => user.email !== state.data.email)));
        console.log('return Success');
        return { state: "success" };
    }
    catch (e) {
        console.log('=====', e.message);
        console.log('=====', e);
        return {
            state: "failed",
            message: errorMessage(e.message)
        };
    }
});
export const getUsersAsync = () => __awaiter(void 0, void 0, void 0, function* () {
    const environment = getEnvironment.get();
    const url = "/users";
    const baseUrl = environment.ias_hub_api_uri;
    const response = yield fetch(`${baseUrl}${url}`, {
        mode: "cors",
        method: "GET",
        headers: {
            Authorization: `Bearer ${accessTokenStore.get()}`,
            ['platformtenantid']: tenantIdStore.get(),
            ['x-api-key']: environment.ias_hub_api_key,
            ['content-type']: "application/json"
        }
    });
    if (response.status.toString().startsWith("2")) {
        const payload = yield response.json();
        return {
            state: "success",
            payload
        };
    }
    else {
        return {
            state: "failed",
            message: errorMessage(response.statusText)
        };
    }
});
export const submitAsync = (state, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const environment = getEnvironment.get();
    const baseUrl = environment.elements2_platform_api_uri;
    const url = "/users/migrate";
    const data = state.data;
    const body = Object.assign(Object.assign({}, data), { addressLine1: data.businessAddress.address1, addressLine2: data.businessAddress.address2, addressLine3: data.businessAddress.address3, townCity: data.businessAddress.town, county: data.businessAddress.county, country: data.businessAddress.country, postcode: data.businessAddress.postcode, tenantId: tenantIdStore.get() });
    body.businessAddress && delete body.businessAddress;
    body.password && delete body.password;
    const response = yield fetch(`${baseUrl}${url}`, {
        mode: "cors",
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessTokenStore.get()}`,
            ['x-id-token']: idTokenStore.get(),
            ['x-api-key']: environment.elements2_platform_api_key,
            ['content-type']: "application/json"
        },
        body: JSON.stringify(body)
    });
    if (response.status.toString().startsWith("2")) {
        return {
            state: "success"
        };
    }
    else {
        return {
            state: "failed",
            message: errorMessage(null)
        };
    }
});
const errorMessage = (errors) => {
    let errText = "Error occurred, please try again";
    if (errors == 'Incorrect username or password.') {
        return errors;
    }
    else if (errors == 'Network error') {
        errText = "Authentication failure, user not able to login ";
    }
    return errText;
};
export default { submitAsync, loginAsync };
