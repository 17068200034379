import React from 'react';
import { hot } from 'react-hot-loader';
import { StepsTrackerItem } from "./StepsTrackerItem";
const StepsTracker = (props) => {
    const { steps, currentIndex, onGotoStep } = props;
    return (React.createElement("div", { className: "onboarding-steps-component" },
        React.createElement("div", { className: "onboarding-steps-component__dashes" }),
        steps.map((step, index) => {
            switch (step.type) {
                case "manual":
                    const isComplete = step.isComplete && index <= currentIndex;
                    return React.createElement(StepsTrackerItem, { key: step.id, step: step, isComplete: isComplete, isCurrent: index === currentIndex, onGotoStep: onGotoStep });
                case "confirmation":
                    const isConfirmationComplete = index <= currentIndex;
                    return React.createElement(StepsTrackerItem, { key: step.id, step: step, isComplete: isConfirmationComplete, isCurrent: index === currentIndex, onGotoStep: onGotoStep });
                default:
                    return null;
            }
        })));
};
export default hot(module)(StepsTracker);
