import React, { useEffect } from 'react';
import ValidationError from "../../components/common/ValidationError";
import { validation } from '../../utils/validation.util';
import newUserDetailsProfileValidation from '../../validation/newUserDetailsProfileValidation';
import NewAddressForm from '../newAddressForm';
const schema = newUserDetailsProfileValidation.create();
const NewUserDetails = (props) => {
    const { setValue } = props;
    const { email, firstName, lastName, businessName, businessTelephoneNumber, businessAddress } = props.data;
    const validationResult = schema.validate(props.data, { abortEarly: false });
    const validationError = (fieldName) => {
        var _a;
        if (Object.keys(props.data).indexOf(fieldName) < 0) {
            return "";
        }
        const error = (_a = validationResult.error) === null || _a === void 0 ? void 0 : _a.details.filter(x => { var _a; return ((_a = x.context) === null || _a === void 0 ? void 0 : _a.key) === fieldName; })[0];
        if (error) {
            return error.message;
        }
        return "";
    };
    useEffect(() => {
        setValue("terms", true);
    }, []);
    return (React.createElement("div", null,
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "email" },
                "Email address",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "email", type: "email", id: "email", value: email ? email : '', disabled: !props.isNewSignUp, onChange: e => setValue("email", e.target.value), className: validationError("email").length > 0 ? 'validation-error' : '' }),
            validationError("email") && React.createElement(ValidationError, { validationError: validationError("email") })),
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "firstName" },
                "First name",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "firstName", type: "text", id: "firstName", value: firstName ? firstName : '', onChange: e => setValue("firstName", e.target.value.replace(/['`]/g, "")), className: validationError("firstName").length > 0 ? 'validation-error' : '' }),
            validationError("firstName") && React.createElement(ValidationError, { validationError: validationError("firstName") })),
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "lastName" },
                "Last name",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "lastName", type: "text", id: "lastName", value: lastName ? lastName : '', onChange: e => setValue("lastName", e.target.value.replace(/['`]/g, "")), className: validationError("lastName").length > 0 ? 'validation-error' : '' }),
            validationError("lastName") && React.createElement(ValidationError, { validationError: validationError("lastName") })),
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "businessName" },
                "Business name",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "businessName", type: "text", id: "businessName", value: businessName ? businessName : '', onChange: e => setValue("businessName", e.target.value), className: validation.getValidationError("businessName", props.data, validationResult).length > 0 ? 'validation-error' : '' }),
            validation.getValidationError("businessName", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("businessName", props.data, validationResult) })),
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "businessTelephoneNumber" },
                "Business telephone number",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "businessTelephoneNumber", type: "text", id: "businessTelephoneNumber", value: businessTelephoneNumber ? businessTelephoneNumber : '', onChange: e => setValue("businessTelephoneNumber", e.target.value), className: validation.getValidationError("businessTelephoneNumber", props.data, validationResult).length > 0 ? 'validation-error' : '' }),
            validation.getValidationError("businessTelephoneNumber", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("businessTelephoneNumber", props.data, validationResult) })),
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "txtEnterPostcode" },
                "Business address",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement(NewAddressForm, { data: businessAddress ? businessAddress : {}, onSubmit: address => setValue("businessAddress", address), setValue: props.setValue }),
            validation.getValidationError("businessAddress", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("businessAddress", props.data, validationResult) }))));
};
export default NewUserDetails;
