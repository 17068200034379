import React from 'react';
import StepIconSelect from "../process/StepIconSelect";
const StepsTrackerItem = (props) => {
    const { step, isComplete, isCurrent, onGotoStep } = props;
    if (isComplete) {
        return (React.createElement("div", { className: step.id !== 'elements1-user-1' ? 'onboarding-steps-component__step clickable' : 'onboarding-steps-component__step', key: step.id, onClick: () => step.id !== 'elements1-user-1' && onGotoStep(step.id), onKeyDown: () => step.id !== 'elements1-user-1' && onGotoStep(step.id) },
            React.createElement("span", { className: "complete" },
                React.createElement(StepIconSelect, { stepId: step.id })),
            React.createElement("p", { className: "complete", style: isCurrent ? { fontWeight: 'bold' } : {} }, step.header)));
    }
    else if (isCurrent) {
        return (React.createElement("div", { className: "onboarding-steps-component__step", key: step.id },
            React.createElement("span", { className: "active" },
                React.createElement(StepIconSelect, { stepId: step.id })),
            React.createElement("p", { className: isCurrent ? "active" : "" }, step.header)));
    }
    return (React.createElement("div", { className: "onboarding-steps-component__step", key: step.id },
        React.createElement("span", { className: step.id === 'elements1-user-1' ? 'complete' : '' },
            React.createElement(StepIconSelect, { stepId: step.id })),
        React.createElement("p", { className: step.id === 'elements1-user-1' ? "active" : "" }, step.header)));
};
export { StepsTrackerItem };
