import React, { useState } from 'react';
const MaskedPassword = (props) => {
    const [masked, setMasked] = useState(props.masked);
    const { value, error, onUpdates, cssClass } = props;
    return (React.createElement("div", { className: "masked-password" },
        React.createElement("div", { className: `${props.error ? "masked-password__input_error" : "masked-password__input"}` },
            React.createElement("input", { name: "password", type: masked ? "password" : "text", id: "password", value: value ? value : '', onChange: e => onUpdates(e.target.value), className: `${cssClass} ${error ? 'validation-error' : ''}` }),
            React.createElement("iris-icon-only", { onClick: () => {
                    setMasked(!masked);
                }, className: `${masked ? "eye" : "eye-off"}`, type: `${masked ? "eye" : "eye-off"}` }))));
};
export { MaskedPassword };
