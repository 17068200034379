var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import getEnvironment from '../getEnvironment';
const taxAndAccountSubmitAsync = (state, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const environment = getEnvironment.get();
    const url = "/tax-and-accounts/signup";
    const baseUrl = environment.elements2_platform_api_uri;
    const data = state.data;
    const body = Object.assign(Object.assign({}, data), { addressLine1: data.businessAddress.address1, addressLine2: data.businessAddress.address2, addressLine3: data.businessAddress.address3, townCity: data.businessAddress.town, county: data.businessAddress.county, country: data.businessAddress.country, postcode: data.businessAddress.postcode });
    const response = yield fetch(`${baseUrl}${url}`, {
        mode: "cors",
        method: "POST",
        headers: {
            //Authorization: `Bearer ${jwtProvider.getJwt()}`,
            //[tenantIdHeader]: tenantIdProvider.getTenantId(),
            // ['x-query-id']: queryId,
            ['x-api-key']: environment.elements2_platform_api_key,
            ['content-type']: "application/json"
        },
        body: JSON.stringify(body)
    });
    if (response.status.toString().startsWith("2")) {
        return {
            state: "success"
        };
    }
    else {
        return {
            state: "failed"
        };
    }
});
export default { taxAndAccountSubmitAsync };
