import React from 'react';
import { hot } from 'react-hot-loader';
const LogoDark = () => {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "128", height: "26", viewBox: "0 0 128 26", fill: "none" },
        React.createElement("path", { d: "M74.8266 17.9815H69.3677V8.19307H74.8266V9.20405H70.5064V12.3575H74.5654V13.3618H70.5064V16.9638H74.8266V17.9815Z", fill: "#24303B" }),
        React.createElement("path", { d: "M77.9479 17.9815H76.836V7.56372H77.9479V17.9815Z", fill: "#24303B" }),
        React.createElement("path", { d: "M83.4067 18.1154C82.3216 18.1154 81.4643 17.7851 80.8347 17.1245C80.2095 16.4639 79.897 15.5467 79.897 14.3728C79.897 13.1899 80.1872 12.2504 80.7677 11.5541C81.3527 10.8578 82.1363 10.5096 83.1187 10.5096C84.0386 10.5096 84.7664 10.8131 85.3022 11.4202C85.8381 12.0227 86.106 12.8195 86.106 13.8104V14.5134H81.049C81.0714 15.3748 81.2879 16.0287 81.6987 16.4751C82.114 16.9214 82.6967 17.1446 83.4469 17.1446C84.2373 17.1446 85.0187 16.9794 85.7912 16.6491V17.64C85.3983 17.8096 85.0254 17.9302 84.6726 18.0016C84.3243 18.0774 83.9024 18.1154 83.4067 18.1154ZM83.1053 11.4403C82.5159 11.4403 82.0448 11.6322 81.692 12.016C81.3437 12.3999 81.1383 12.9311 81.0758 13.6095H84.9138C84.9138 12.9087 84.7575 12.3731 84.4449 12.0027C84.1323 11.6277 83.6858 11.4403 83.1053 11.4403Z", fill: "#24303B" }),
        React.createElement("path", { d: "M97.3653 17.9815V13.2078C97.3653 12.6231 97.2403 12.1857 96.9902 11.8955C96.7402 11.6009 96.3517 11.4536 95.8248 11.4536C95.1327 11.4536 94.6214 11.6523 94.2909 12.0495C93.9605 12.4468 93.7953 13.0583 93.7953 13.884V17.9815H92.6834V13.2078C92.6834 12.6231 92.5584 12.1857 92.3083 11.8955C92.0583 11.6009 91.6676 11.4536 91.1362 11.4536C90.4396 11.4536 89.9283 11.6634 89.6024 12.083C89.2809 12.4981 89.1201 13.181 89.1201 14.1317V17.9815H88.0082V10.6435H88.9125L89.0933 11.6478H89.1469C89.3568 11.2907 89.6515 11.0118 90.031 10.8109C90.415 10.61 90.8437 10.5096 91.317 10.5096C92.4646 10.5096 93.2148 10.9247 93.5676 11.7549H93.6211C93.84 11.3711 94.157 11.0676 94.5723 10.8444C94.9875 10.6212 95.4609 10.5096 95.9922 10.5096C96.8228 10.5096 97.4435 10.7239 97.8543 11.1524C98.2696 11.5764 98.4772 12.2571 98.4772 13.1944V17.9815H97.3653Z", fill: "#24303B" }),
        React.createElement("path", { d: "M103.876 18.1154C102.791 18.1154 101.933 17.7851 101.304 17.1245C100.679 16.4639 100.366 15.5467 100.366 14.3728C100.366 13.1899 100.656 12.2504 101.237 11.5541C101.822 10.8578 102.605 10.5096 103.588 10.5096C104.508 10.5096 105.235 10.8131 105.771 11.4202C106.307 12.0227 106.575 12.8195 106.575 13.8104V14.5134H101.518C101.54 15.3748 101.757 16.0287 102.168 16.4751C102.583 16.9214 103.166 17.1446 103.916 17.1446C104.706 17.1446 105.488 16.9794 106.26 16.6491V17.64C105.867 17.8096 105.494 17.9302 105.142 18.0016C104.793 18.0774 104.371 18.1154 103.876 18.1154ZM103.574 11.4403C102.985 11.4403 102.514 11.6322 102.161 12.016C101.813 12.3999 101.607 12.9311 101.545 13.6095H105.383C105.383 12.9087 105.227 12.3731 104.914 12.0027C104.601 11.6277 104.155 11.4403 103.574 11.4403Z", fill: "#24303B" }),
        React.createElement("path", { d: "M113.501 17.9815V13.2346C113.501 12.6365 113.365 12.1901 113.092 11.8955C112.82 11.6009 112.393 11.4536 111.813 11.4536C111.045 11.4536 110.482 11.6612 110.125 12.0763C109.768 12.4914 109.589 13.1765 109.589 14.1317V17.9815H108.477V10.6435H109.382L109.562 11.6478H109.616C109.844 11.2863 110.163 11.0073 110.574 10.8109C110.985 10.61 111.442 10.5096 111.947 10.5096C112.831 10.5096 113.496 10.7239 113.943 11.1524C114.389 11.5764 114.613 12.2571 114.613 13.1944V17.9815H113.501Z", fill: "#24303B" }),
        React.createElement("path", { d: "M119.274 17.1981C119.471 17.1981 119.661 17.1848 119.844 17.158C120.027 17.1267 120.172 17.0955 120.279 17.0642V17.9145C120.159 17.9726 119.98 18.0194 119.743 18.0551C119.511 18.0953 119.301 18.1154 119.114 18.1154C117.694 18.1154 116.984 17.3678 116.984 15.8725V11.5072H115.932V10.9716L116.984 10.5096L117.453 8.94294H118.096V10.6435H120.226V11.5072H118.096V15.8256C118.096 16.2675 118.201 16.6067 118.41 16.8433C118.62 17.0799 118.908 17.1981 119.274 17.1981Z", fill: "#24303B" }),
        React.createElement("path", { d: "M126.488 15.9796C126.488 16.6625 126.234 17.1892 125.725 17.5597C125.216 17.9302 124.501 18.1154 123.581 18.1154C122.608 18.1154 121.849 17.9614 121.304 17.6534V16.6224C121.657 16.8009 122.034 16.9415 122.436 17.0442C122.842 17.1468 123.233 17.1981 123.608 17.1981C124.189 17.1981 124.635 17.1066 124.948 16.9236C125.26 16.7362 125.417 16.4527 125.417 16.0733C125.417 15.7877 125.291 15.5444 125.041 15.3436C124.796 15.1382 124.314 14.8972 123.595 14.6205C122.911 14.3661 122.425 14.1451 122.134 13.9577C121.849 13.7657 121.634 13.5492 121.491 13.3082C121.353 13.0672 121.284 12.7793 121.284 12.4445C121.284 11.8464 121.527 11.3755 122.014 11.0318C122.501 10.6837 123.168 10.5096 124.017 10.5096C124.807 10.5096 125.579 10.6703 126.334 10.9917L125.939 11.8955C125.202 11.592 124.535 11.4403 123.936 11.4403C123.409 11.4403 123.012 11.5228 122.744 11.688C122.476 11.8531 122.342 12.0808 122.342 12.3709C122.342 12.5673 122.391 12.7347 122.489 12.873C122.592 13.0114 122.755 13.1431 122.978 13.268C123.202 13.393 123.63 13.5738 124.264 13.8104C125.135 14.1273 125.722 14.4464 126.026 14.7678C126.334 15.0891 126.488 15.4931 126.488 15.9796Z", fill: "#24303B" }),
        React.createElement("path", { d: "M61.7217 0H61.976H62.184V25.9768H61.976H61.7217V0Z", fill: "#24303B" }),
        React.createElement("path", { d: "M12.8289 5.09842C12.8308 5.06005 12.8476 5.0239 12.8756 4.99744C12.9037 4.97098 12.941 4.95625 12.9797 4.9563H16.2635C16.3019 4.95859 16.338 4.97536 16.3643 5.00317C16.3907 5.03098 16.4053 5.06773 16.4053 5.1059V20.9019C16.4053 20.9392 16.3903 20.9749 16.3637 21.0013C16.3371 21.0277 16.3011 21.0425 16.2635 21.0425H12.9722C12.9337 21.0426 12.8966 21.028 12.8686 21.0019C12.8405 20.9758 12.8236 20.94 12.8213 20.9019L12.8289 5.09842Z", fill: "#24303B" }),
        React.createElement("path", { d: "M6.33525 10.7187C6.33756 10.6806 6.35446 10.6449 6.3825 10.6187C6.41054 10.5926 6.4476 10.5781 6.48609 10.5781H9.75779C9.79469 10.5803 9.82951 10.5958 9.85565 10.6217C9.88179 10.6476 9.89742 10.6821 9.89957 10.7187V20.8914C9.89957 20.9287 9.88464 20.9644 9.85805 20.9908C9.83146 21.0172 9.79539 21.032 9.75779 21.032H6.47402C6.43553 21.032 6.39848 21.0175 6.37044 20.9914C6.34239 20.9652 6.32549 20.9295 6.32318 20.8914L6.33525 10.7187Z", fill: "#62B9E9" }),
        React.createElement("path", { d: "M0 15.9561C0 15.9188 0.0149384 15.883 0.041529 15.8566C0.0681195 15.8302 0.104184 15.8154 0.141789 15.8154H3.42556C3.46273 15.8172 3.49792 15.8326 3.52437 15.8585C3.55082 15.8845 3.56666 15.9192 3.56885 15.9561V20.9017C3.56666 20.9386 3.55082 20.9733 3.52437 20.9993C3.49792 21.0252 3.46273 21.0406 3.42556 21.0424H0.141789C0.104184 21.0424 0.0681195 21.0275 0.041529 21.0012C0.0149384 20.9748 0 20.939 0 20.9017L0 15.9561Z", fill: "#D60926" }),
        React.createElement("path", { d: "M35.2406 5.09839C35.2406 5.06109 35.2555 5.02532 35.2821 4.99895C35.3087 4.97258 35.3447 4.95776 35.3823 4.95776H38.6661C38.7037 4.95776 38.7398 4.97258 38.7664 4.99895C38.793 5.02532 38.8079 5.06109 38.8079 5.09839V20.9018C38.8079 20.9391 38.793 20.9749 38.7664 21.0013C38.7398 21.0276 38.7037 21.0425 38.6661 21.0425H35.3823C35.3447 21.0425 35.3087 21.0276 35.2821 21.0013C35.2555 20.9749 35.2406 20.9391 35.2406 20.9018V5.09839Z", fill: "#24303B" }),
        React.createElement("path", { d: "M33.2661 20.9273L29.385 15.2979C31.4454 14.5395 32.8543 12.9044 32.8543 10.3118V10.2625C32.8543 8.74557 32.3686 7.48147 31.465 6.58538C30.4031 5.53222 28.8103 4.95776 26.7423 4.95776H19.4703C19.4327 4.95776 19.3967 4.97258 19.3701 4.99895C19.3435 5.02532 19.3285 5.06109 19.3285 5.09839V20.9018C19.3285 20.9391 19.3435 20.9749 19.3701 21.0013C19.3967 21.0276 19.4327 21.0425 19.4703 21.0425H22.7541C22.7917 21.0425 22.8278 21.0276 22.8543 21.0013C22.8809 20.9749 22.8959 20.9391 22.8959 20.9018V15.8948H25.7L29.1693 21.0425H33.1982C33.2827 21.0425 33.3098 20.9901 33.2661 20.9273ZM22.9095 12.7697L22.9276 8.15166H26.4391C28.1783 8.15166 29.2432 8.93256 29.2432 10.4495V10.4959C29.2432 11.8512 28.2477 12.7697 26.51 12.7697H22.9095Z", fill: "#24303B" }),
        React.createElement("path", { d: "M48.2685 11.4143C45.4644 10.7022 44.7691 10.3566 44.7691 9.30048V9.25411C44.7691 8.47321 45.4886 7.85238 46.8552 7.85238C48.1403 7.85238 49.4662 8.38046 50.8132 9.26458L50.9776 9.37379C51.0059 9.38415 51.0368 9.38584 51.0661 9.37864C51.0954 9.37144 51.1218 9.35568 51.142 9.33339L52.8661 6.86055C52.8789 6.83668 52.8839 6.80946 52.8804 6.78265C52.8769 6.75584 52.865 6.73078 52.8465 6.71095C52.7967 6.67655 52.7485 6.63616 52.6957 6.60025L52.6806 6.58978C51.0877 5.3945 49.1555 4.72729 46.911 4.72729C43.5925 4.72729 41.2334 6.6571 41.2334 9.57574V9.62212C41.2334 12.816 43.3451 13.7121 46.6093 14.5394C49.3244 15.229 49.8765 15.6883 49.8765 16.5844V16.6307C49.8765 17.5732 48.9956 18.1477 47.5355 18.1477C45.7616 18.1477 44.2849 17.4505 42.8806 16.3435L42.784 16.2672C42.7573 16.2532 42.7267 16.248 42.6968 16.2526C42.6669 16.2572 42.6393 16.2712 42.6181 16.2927L40.6572 18.6069C40.6416 18.631 40.6333 18.6591 40.6333 18.6877C40.6333 18.7164 40.6416 18.7444 40.6572 18.7685L40.808 18.8986C42.6731 20.4391 45.0253 21.2796 47.4525 21.2728C50.952 21.2728 53.4076 19.4776 53.4076 16.2867V16.2463C53.4182 13.4368 51.5644 12.264 48.2685 11.4143Z", fill: "#24303B" })));
};
export default hot(module)(LogoDark);
