import React from 'react';
import { hot } from 'react-hot-loader';
const NewUSerDetailsForm = (props) => {
    const { title, description, isComplete, } = props.step;
    const { onNextStep } = props;
    const submit = (e) => {
        e.preventDefault();
        onNextStep();
    };
    return (React.createElement("div", { className: "onboarding-step" },
        React.createElement("h1", { className: "onboarding-step__title" }, title),
        React.createElement("p", { className: "onboarding-step__description" }, description),
        React.createElement("form", { onSubmit: e => submit(e) },
            props.children,
            React.createElement("section", null,
                React.createElement("iris-button", { disabled: !isComplete, type: "submit", class: "next-btn" }, "Sign up"),
                React.createElement("p", { className: 'terms-label' },
                    "By continuing you agree to ",
                    React.createElement("a", { href: "https://www.iris.co.uk/terms-conditions/", target: "_blank" }, "IRIS terms of use"))))));
};
export default hot(module)(NewUSerDetailsForm);
