var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
require('es6-promise').polyfill();
require('isomorphic-fetch');
import '../scss/app.scss';
import { setEnvironment } from '../config/environment';
const set = () => __awaiter(void 0, void 0, void 0, function* () {
    let element = document.getElementById('environment_src');
    let src = element === null || element === void 0 ? void 0 : element.src;
    try {
        if (src) {
            let response = yield fetch(src);
            let environment = yield response.json();
            setEnvironment(environment);
        }
    }
    catch (error) {
    }
});
export default set;
