import React from 'react';
import { hot } from 'react-hot-loader';
import ErrorContainer from "./ErrorContainer";
const inner = (status, retry, onPreviousStep) => {
    switch (status) {
        case "pending":
        case "processing":
            return React.createElement("iris-loading-spinner", { size: "80" });
        case "failed":
            return React.createElement("div", { className: "retry-container" },
                React.createElement(ErrorContainer, null),
                React.createElement("section", null,
                    React.createElement("iris-button", { type: "submit", onClick: retry }, "Retry"),
                    React.createElement("a", { className: "onboarding-step__link", onClick: onPreviousStep, onKeyDown: onPreviousStep }, `Go back`)));
        case "success":
            return null;
    }
};
const AutomaticStep = (props) => {
    const { title, description, status } = props.step;
    const { submit, onPreviousStep } = props;
    if (status === "pending") {
        submit();
    }
    return (React.createElement("div", { className: "onboarding-step" },
        React.createElement("h1", { className: "onboarding-step__title" }, title),
        React.createElement("p", { className: "onboarding-step__description" }, description),
        React.createElement("form", null,
            inner(status, () => submit(), onPreviousStep),
            props.children)));
};
export default hot(module)(AutomaticStep);
