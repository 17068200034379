import React from 'react';
import { hot } from 'react-hot-loader';
const validationError = (props) => {
    const { validationError } = props;
    if (!validationError) {
        return React.createElement("p", { className: "validation-error" });
    }
    return (React.createElement("p", { className: "validation-error" },
        React.createElement("svg", { stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 24 24", height: "1em", width: "1em", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", null,
                React.createElement("path", { fill: "none", d: "M0 0h24v24H0z" }),
                React.createElement("path", { d: "M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" }))),
        React.createElement("span", null, validationError)));
};
export default hot(module)(validationError);
