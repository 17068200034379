import React from 'react';
import { hot } from 'react-hot-loader';
import UserDetailsStep from "./UserDetailsStep";
import BusinessDetailsStep from "./BusinessDetailsStep";
import VerificationEmail from "./VerificationEmail";
import Submitting from "./Submitting";
import CognitoLogin from "./CognitoLoginStep";
import Elements1UsersStep from "./Elements1UsersStep";
import NewUserDetails from './NewUserDetails';
const ProcessScreenSelect = (props) => {
    switch (props.step.id) {
        case "condensed-user":
            return React.createElement(NewUserDetails, { isNewSignUp: true, data: props.data, setValue: props.setValue });
        case "new-user-1":
            return React.createElement(UserDetailsStep, { isNewSignUp: true, data: props.data, setValue: props.setValue });
        case "new-user-2":
            return React.createElement(BusinessDetailsStep, { data: props.data, setValue: props.setValue });
        case "new-user-3":
            return React.createElement(Submitting, { data: props.data, setValue: props.setValue });
        case "new-user-4":
            return React.createElement(VerificationEmail, null);
        case "new-tax-user-1":
            return React.createElement(UserDetailsStep, { isNewSignUp: true, data: props.data, setValue: props.setValue });
        case "new-tax-user-2":
            return React.createElement(BusinessDetailsStep, { data: props.data, setValue: props.setValue });
        case "new-tax-user-3":
            return React.createElement(Submitting, { data: props.data, setValue: props.setValue });
        case "new-tax-user-4":
            return React.createElement(VerificationEmail, null);
        case "elements1-user-1":
            return React.createElement(CognitoLogin, { data: props.data, setValue: props.setValue });
        case "elements1-user-1b":
            return React.createElement(Submitting, { data: props.data, setValue: props.setValue });
        case "elements1-user-2":
            return React.createElement(UserDetailsStep, { data: props.data, setValue: props.setValue });
        case "elements1-user-3":
            return React.createElement(BusinessDetailsStep, { data: props.data, setValue: props.setValue });
        case "elements1-user-4":
            return React.createElement(Elements1UsersStep, { data: props.data, setValue: props.setValue });
        case "elements1-user-5":
            return React.createElement("div", { id: "empty" });
        case "elements1-user-6":
            return React.createElement("div", { id: "empty" });
        default:
            return React.createElement("div", null, "Something went wrong");
    }
};
export default hot(module)(ProcessScreenSelect);
