import React from 'react';
import { hot } from 'react-hot-loader';
import AddressForm from "../AddressForm";
import Selector from "../common/Selector";
import newUserStep2Validation from "../../validation/newUserBusinessValidation";
import ValidationError from "../../components/common/ValidationError";
import { validation } from "../../utils/validation.util";
const schema = newUserStep2Validation.create();
const BusinessDetailsStep = (props) => {
    const { setValue } = props;
    const { businessName, businessTelephoneNumber, businessSize, businessAddress } = props.data;
    const validationResult = schema.validate(props.data, { abortEarly: false });
    const businessSizeList = [
        {
            value: "Sole practitioner",
            name: "Sole practitioner"
        },
        {
            value: "Small (1-4)",
            name: "Small (1-4)"
        },
        {
            value: "Medium (5-9)",
            name: "Medium (5-9)"
        },
        {
            value: "Large (10-49)",
            name: "Large (10-49)"
        },
        {
            value: "Very large (50+)",
            name: "Very large (50+)"
        }
    ];
    return (React.createElement("div", null,
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "businessName" },
                "Business name",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "businessName", type: "text", id: "businessName", value: businessName ? businessName : '', onChange: e => setValue("businessName", e.target.value), className: validation.getValidationError("businessName", props.data, validationResult).length > 0 ? 'validation-error' : '' }),
            validation.getValidationError("businessName", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("businessName", props.data, validationResult) })),
        React.createElement("section", null,
            React.createElement(Selector, { onChanged: e => setValue("businessSize", e.detail), size: "45", label: "Business size", required: true, value: businessSize ? businessSize : 'Select size', data: businessSizeList }),
            validation.getValidationError("businessSize", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("businessSize", props, validationResult) })),
        React.createElement("section", null,
            React.createElement("label", { htmlFor: "businessTelephoneNumber" },
                "Business telephone number",
                React.createElement("span", { className: "asterisk" }, "*")),
            React.createElement("input", { name: "businessTelephoneNumber", type: "text", id: "businessTelephoneNumber", value: businessTelephoneNumber ? businessTelephoneNumber : '', onChange: e => setValue("businessTelephoneNumber", e.target.value), className: validation.getValidationError("businessTelephoneNumber", props.data, validationResult).length > 0 ? 'validation-error' : '' }),
            validation.getValidationError("businessTelephoneNumber", props.data, validationResult) && React.createElement(ValidationError, { validationError: validation.getValidationError("businessTelephoneNumber", props.data, validationResult) })),
        React.createElement("section", null,
            React.createElement("p", { className: "onboarding-step__subHeading" }, "Business address"),
            React.createElement(AddressForm, { data: businessAddress ? businessAddress : {}, onSubmit: address => setValue("businessAddress", address), setValue: props.setValue }))));
};
export default hot(module)(BusinessDetailsStep);
