import React from 'react';
import { hot } from 'react-hot-loader';
import ProcessContainer from "../components/common/ProcessContainer";
import ProcessSelectorContainer from "../components/common/ProcessSelectorContainer";
const JoinElements = () => {
    return (React.createElement("div", null,
        React.createElement(ProcessSelectorContainer, null),
        React.createElement(ProcessContainer, null)));
};
export default hot(module)(JoinElements);
